import React, {
	// RefObject,
	useEffect,
	// useLayoutEffect,
	//  useRef,
	useState,
} from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { IInput } from 'components/booking/FormFields';
import ApiDirectusDynamic, { ConnectedClinic } from 'apiFolder/directus-dynamic';

import Logo from 'images/Logo_blue.svg';
import handIcon from 'images/icons/handIcon.png';
import clinikoLogo from 'images/cliniko-logo.png';
import nookalLogo from 'images/nookal-logo.png';

import Input from 'components/common/Input';
import Footer from '../components/Footer/Footer';
import ThemeProvider from 'components/common/ThemeProvider';
import LoaderComponent from 'components/common/Loader';
import { H1 } from 'components/common/Styles';

interface IConnected {
	apikey: { value: string; isValid: boolean };
	connectedClinic: { value: string; isValid: boolean };
	connectedSuburb: { value: string; isValid: boolean };
}

const CLINIKO = 'cliniko';
const NOOKAL = 'nookal';

const ERROR_MESSAGES = {
	error: "This field shouldn't be empty",
};

const HowToGuide = () => {
	const [provider, setProvider] = useState<string>('');
	const [tabs, setTabs] = useState([
		{ name: CLINIKO, isActive: true },
		{ name: NOOKAL, isActive: false },
	]);
	const [connectedClinicData, setConnectedClinicData] = useState<IConnected>({
		apikey: { value: '', isValid: false },
		connectedClinic: { value: '', isValid: false },
		connectedSuburb: { value: '', isValid: false },
	});
	const [apikeyInput, setApikeyInput] = useState<IInput>({
		id: 'apikey',
		value: '',
		error: '',
		isEdited: false,
	});
	const [clinicNameInput, setClinicNameInput] = useState<IInput>({
		id: 'connectedClinic',
		value: '',
		error: '',
		isEdited: false,
	});
	const [suburbInput, setSuburbInput] = useState<IInput>({
		id: 'connectedSuburb',
		value: '',
		error: '',
		isEdited: false,
	});
	const [isSending, setIsSending] = useState<boolean>(false);
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [errMessage, setErrMessage] = useState<string>('');

	// const apikeyInputElement = useRef<RefObject<HTMLInputElement>>({});
	// const clinicNameInputElement = useRef<RefObject<HTMLInputElement>>();
	// const suburbInputElement = useRef<RefObject<HTMLInputElement>>();

	// const refs = {
	// 	apikey: apikeyInputElement,
	// 	connectedClinic: clinicNameInputElement,
	// 	connectedSuburb: suburbInputElement,
	// };

	useEffect(() => {
		if (window.location.search) {
			const searchParts = window.location.search.split('?');
			const query = decodeURIComponent(searchParts[1]);
			const getEqualIndex = query.indexOf('=');
			const queryProvider = query.slice(getEqualIndex + 1).toLowerCase();

			setProvider(queryProvider);

			const initTabs = tabs.map((tab) => {
				if (tab.name === queryProvider) {
					return {
						...tab,
						isActive: true,
					};
				}

				return {
					...tab,
					isActive: false,
				};
			});

			setTabs(initTabs);

			const url = window.location.pathname;
			window.history.pushState({}, '', url);
			return;
		}
		setProvider(CLINIKO);
	}, []);

	useEffect(() => {
		if (isSending) {
			// blurApikeyHandler();
			// blurClinicNameHandler();
			// blurSuburbHandler();

			// const inputs = [apikeyInput, clinicNameInput, suburbInput];

			// if (invalidInputsAmount(inputs)) {
			// 	const invalidInput = firstInvalidInput(inputs) || refs.connectedClinic;
			// 	refs[invalidInput?.id].current.scrollIntoView({
			// 		behavior: 'smooth',
			// 		block: 'center',
			// 	});

			// 	setIsSending(false);
			// 	return;
			// }

			// ===== old =====
			// const connectedData = {
			// 	name: clinicNameInput.value,
			// 	suburb: suburbInput.value,
			// 	apiKey: apikeyInput.value,
			// };

			// ===== new =====
			const connectedData = {
				name: clinicNameInput.value,
				suburb: suburbInput.value,
				apiKey: apikeyInput.value,
				provider: getFirstLetterCapitalized(provider),
			};

			sendConnectedClinicData(connectedData)
				.then(() => {
					setIsSuccess(true);
				})
				.catch((err) => {
					setErrMessage(err.response.data.message);
					setIsSuccess(false);
				})
				.finally(() => {
					setIsSending(false);
					setIsSubmitted(true);
				});
		}
	}, [isSending]);

	const sendConnectedClinicData = async (data: ConnectedClinic) => {
		const directusDynamicApi = ApiDirectusDynamic.getInstance();
		return directusDynamicApi.connectClinicDirectus(data);
	};

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		setIsSending(true);
	};

	const onChangeHandler = (
		htmlInputValue: string,
		formInput: IInput,
		errorMessage: string,
		setInput: (inputEl: IInput) => void,
	) => {
		const input = {
			...formInput,
			value: htmlInputValue,
		};
		setInput(input);
		const isValid = true; //validateInput(input, errorMessage, setInput, validateRequiredInput);

		setConnectedClinicData({
			...connectedClinicData,
			[input.id!]: {
				value: htmlInputValue,
				isValid,
			},
		});
	};

	const handleClick = (providerName: string) => {
		setTabs(
			tabs.map((tab) => {
				if (providerName === tab.name) {
					setProvider(providerName);
					tab.isActive = true;
					return tab;
				}
				tab.isActive = false;
				return tab;
			}),
		);
	};

	const getFirstLetterCapitalized = (name: string) => {
		return name.charAt(0).toUpperCase() + name.slice(1);
	};

	// const validateRequiredInput = (input: IInput) => input.value.trim().length === 0;

	// const validateInput = (
	// 	inputElement: IInput,
	// 	errorMessage: string,
	// 	setMethod: (inputEl: IInput) => void,
	// 	fn: (inputElem: IInput) => boolean,
	// ): boolean => {
	// 	const error = fn(inputElement) ? errorMessage : '';
	// 	setMethod({
	// 		...inputElement,
	// 		error,
	// 		isEdited: true,
	// 	});
	// 	return error === '';
	// };

	// const isInputError = (formInput: IInput) =>
	// 	(formInput.isEdited && formInput.value.length === 0) || formInput.error.length > 0;

	// const blurApikeyHandler = () => {
	// 	validateInput(apikeyInput, ERROR_MESSAGES.error, setApikeyInput, validateRequiredInput);
	// };

	// const blurClinicNameHandler = () => {
	// 	validateInput(clinicNameInput, ERROR_MESSAGES.error, setClinicNameInput, validateRequiredInput);
	// };

	// const blurSuburbHandler = () => {
	// 	validateInput(suburbInput, ERROR_MESSAGES.error, setSuburbInput, validateRequiredInput);
	// };

	// const invalidInputsAmount = (items: IInput[]) =>
	// 	items.reduce((totalErrorsNumber, item) => {
	// 		if (item.error !== '' || item.isEdited === false) {
	// 			totalErrorsNumber++;
	// 		}
	// 		return totalErrorsNumber;
	// 	}, 0);

	// const firstInvalidInput = (items: IInput[]) =>
	// 	[...items].find((item) => {
	// 		if (item.error !== '' || item.isEdited === false) {
	// 			return item;
	// 		}
	// 	});

	return (
		<ThemeProvider>
			<HeaderWrapper>
				<HeaderInner>
					<LogoWrapper>
						<Link to='/'>
							<img src={Logo} alt='' width='187px' />
						</Link>
					</LogoWrapper>
				</HeaderInner>
			</HeaderWrapper>
			<Content>
				<TitleBlock>
					<Wrapper>
						<H1>Generate your API Key to display your availabilities</H1>
						<TopParagraph>
							Make it easy for patients to see and book your available appointments by integrating your
							PMS with the BookPhysio.com system.
						</TopParagraph>
						<Green>Average time to complete: 63 seconds</Green>
					</Wrapper>
				</TitleBlock>
				<Wrapper>
					<TabBox>
						<Row>
							<TitleSelect>
								<Span>Select your PMS</Span>
								<HandIcon>
									<img src={handIcon} alt='Select your PMS' />
								</HandIcon>
							</TitleSelect>
							<ImgRow>
								<TitleImage isActive={tabs[0].isActive} onClick={() => handleClick(CLINIKO)}>
									<ProviderIcon>
										<img src={clinikoLogo} alt='Cliniko' />
									</ProviderIcon>
								</TitleImage>
								<TitleImage isActive={tabs[1].isActive} onClick={() => handleClick(NOOKAL)}>
									<ProviderIconNookal>
										<img src={nookalLogo} alt='Nookal' />
									</ProviderIconNookal>
								</TitleImage>
							</ImgRow>
						</Row>
						<RowLine />
					</TabBox>
					<Block>
						<H3>Step 1.</H3>
						<Paragraph>
							{provider === CLINIKO ? (
								<>
									Click on <strong>My Info:</strong> under your name.
								</>
							) : (
								<>
									Click on <strong>Setup:</strong> in the top navigation bar.
								</>
							)}
						</Paragraph>
						<ImgBlock>
							{provider === CLINIKO ? (
								<StaticImage src='../images/howToGuide/01.jpeg' alt='1st step' width={700} />
							) : (
								<StaticImage
									src='../images/howToGuide/01n-c.png'
									alt='1st step'
									width={700}
									quality={90}
								/>
							)}
						</ImgBlock>
					</Block>
					<Block>
						<H3>Step 2.</H3>
						<Paragraph>
							{provider === CLINIKO ? (
								<>
									Under <strong>API keys</strong>, click <strong>Manage API keys</strong>.
								</>
							) : (
								<>
									Under <strong>Connections</strong>, click <strong>API keys</strong>.
								</>
							)}
						</Paragraph>
						<ImgBlock>
							{provider === CLINIKO ? (
								<StaticImage src='../images/howToGuide/02.jpeg' alt='2d step' width={700} />
							) : (
								<StaticImage
									src='../images/howToGuide/02n-c.png'
									alt='2d step'
									width={700}
									quality={90}
								/>
							)}
						</ImgBlock>
					</Block>
					<Block>
						<H3>Step 3.</H3>
						<Paragraph>
							{provider === CLINIKO ? (
								<>
									Next, click <strong>Add an API key.</strong>
								</>
							) : (
								<>
									Ensure all of your locations are selected, and type{' '}
									<strong>"BookPhysio.com"</strong> as the name for your new API key.
								</>
							)}
						</Paragraph>
						<ImgBlock>
							{provider === CLINIKO ? (
								<StaticImage
									src='../images/howToGuide/03.jpeg'
									alt='3d step'
									width={700}
									quality={90}
								/>
							) : (
								<StaticImage
									src='../images/howToGuide/03n-c.png'
									alt='3d step'
									width={700}
									quality={90}
								/>
							)}
						</ImgBlock>
					</Block>
					<Block>
						<H3>Step 4.</H3>
						<Paragraph>
							{provider === CLINIKO ? (
								<>
									Add <strong>"BookPhysio.com"</strong> as the name for your new API key.
								</>
							) : (
								<>
									Copy your <strong>API key</strong>, and make sure to click{' '}
									<strong>Save Changes</strong>.
								</>
							)}
						</Paragraph>
						<ImgBlock>
							{provider === CLINIKO ? (
								<StaticImage
									src='../images/howToGuide/04.png'
									alt='4th step'
									width={700}
									quality={100}
								/>
							) : (
								<StaticImage
									src='../images/howToGuide/04n-c.png'
									alt='4th step'
									width={700}
									quality={90}
								/>
							)}
						</ImgBlock>
					</Block>
					{provider === CLINIKO ? (
						<Block>
							<H3>Step 5.</H3>
							<Paragraph>
								Once you have clicked <strong>[Create API key]</strong>, you will see a long string of
								letters and numbers - this is your new API key. Copy the key and submit it in{' '}
								<strong>Step 6</strong>.
							</Paragraph>
							<ImgBlock>
								<StaticImage src='../images/howToGuide/05.jpeg' alt='5th step' width={700} />
							</ImgBlock>
						</Block>
					) : null}
					<Block>
						<H3>{`Step ${provider === CLINIKO ? '6' : '5'}.`}</H3>
						<Paragraph>
							<strong>Submit your API key.</strong> Paste your key below and enter your details so we know
							who has sent us the new API Key.
						</Paragraph>
						{isSubmitted && isSuccess ? (
							<SubmittedText isSuccess={isSuccess} isSubmitted={isSubmitted}>
								Thank you for submitting your API key - we received it perfectly
							</SubmittedText>
						) : (
							<>
								{isSubmitted && !isSuccess && (
									<SubmittedText isSuccess={isSuccess} isSubmitted={isSubmitted}>
										{errMessage}
									</SubmittedText>
								)}
								<Form onSubmit={handleSubmit}>
									<Label>API Key</Label>
									<Input
										id='apiKey'
										name='apiKey'
										value={apikeyInput.value}
										// inputRef={apikeyInputElement}
										isModal
										onChange={(e) =>
											onChangeHandler(e, apikeyInput, ERROR_MESSAGES.error, setApikeyInput)
										}
										// beforeContent='First name*'
										fullWidth
										maxWidth='100%'
										// isError={isInputError(apikeyInput)}
										// errorText={
										// 	apikeyInput.error.length > 0 ? apikeyInput.error : ERROR_MESSAGES.error
										// }
										// onBlur={blurApikeyHandler}
									/>
									<Label>Clinic name</Label>
									<Input
										id='clinicName'
										name='clinicName'
										value={clinicNameInput.value}
										// inputRef={clinicNameInputElement}
										isModal
										onChange={(e) =>
											onChangeHandler(
												e,
												clinicNameInput,
												ERROR_MESSAGES.error,
												setClinicNameInput,
											)
										}
										// beforeContent='First name*'
										fullWidth
										maxWidth='100%'
										// isError={isInputError(clinicNameInput)}
										// errorText={
										// 	clinicNameInput.error.length > 0
										// 		? clinicNameInput.error
										// 		: ERROR_MESSAGES.error
										// }
										// onBlur={blurClinicNameHandler}
									/>
									<Label>Suburb</Label>
									<Input
										id='suburbName'
										name='suburbName'
										value={suburbInput.value}
										// inputRef={suburbInputElement}
										isModal
										onChange={(e) =>
											onChangeHandler(e, suburbInput, ERROR_MESSAGES.error, setSuburbInput)
										}
										// beforeContent='First name*'
										fullWidth
										maxWidth='100%'
										// isError={isInputError(suburbInput)}
										// errorText={
										// 	suburbInput.error.length > 0 ? suburbInput.error : ERROR_MESSAGES.error
										// }
										// onBlur={blurSuburbHandler}
									/>
									<Button type='submit'>Sign up</Button>
									{isSending && <LoaderComponent zIndex={103} />}
								</Form>
							</>
						)}
					</Block>
				</Wrapper>
			</Content>
			<Footer />
		</ThemeProvider>
	);
};

const HeaderWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	border-bottom: 1px solid #00000026;
	background-color: #ffffff;
	z-index: 105;
`;

const HeaderInner = styled.div`
	max-width: 1140px;
	margin: 0 auto;
`;

export const LogoWrapper = styled.div`
	max-width: 1140px;
	align-items: center;
	padding: 24px;
	margin: 0 auto;
	display: inline-block;
	@media (max-width: 767px) {
		flex: 1 1 auto;
		padding: 8px 16px;
		& img {
			width: 150px;
		}

		& a {
			margin-left: 5px;
		}
	}
	@media (max-width: 414px) {
		flex: 0 1 120px;
		& img {
			width: 120px;
		}
	}
	@media (max-width: 355px) {
		flex: 0 1 90px;
		margin-top: 5px;
		& img {
			width: 100px;
		}
	}
`;

const Content = styled.div`
	// max-width: 1140px;
	// margin: 0 auto;
	padding: 76px 0 0;
	@media (max-width: 767px) {
		padding: 40px 0 0;
	}
	@media (max-width: 414px) {
		padding: 35px 0 0;
	}
`;

const Paragraph = styled.p`
	font-size: 18px;
	color: #152231;
	margin-bottom: 26px;
`;
const TopParagraph = styled.p`
	font-size: 18px;
	color: #152231;
	margin: 24px 0;
`;

const TitleBlock = styled.div`
	display: relative;
	left: 0;
	width: 100%;
	background-color: #f4f4f5;
	padding: 40px 0;

	@media (max-width: 767px) {
		padding: 20px 0;
	}
`;

const Block = styled.div`
	border-bottom: 1px solid rgb(232, 232, 232);
	padding-bottom: 45px;
	margin-bottom: 65px;
`;

const Green = styled.p`
	color: #28a745;
`;

const H3 = styled.h3`
	margin-bottom: 8px;
`;
const Wrapper = styled.div`
	max-width: 730px;
	margin: 0 auto;
	padding: 0 15px;
`;

const Form = styled.form`
	position: relative;
	padding: 20px 15px;
`;

const Label = styled.label`
	display: inline-block;
	margin-bottom: 0.5rem;
	font-family: inherit;
	touch-action: manipulation;
	line-height: inherit;
`;

const Button = styled.button`
	color: rgb(255, 255, 255);
	background: rgb(23, 95, 255);
	font-weight: 500;
	width: 100%;
	line-height: 1.25;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	border: 1px solid transparent;
	padding: 0.5rem 1rem;
	font-size: 1rem;
	border-radius: 3px;
	transition: all 0.2s ease-in-out 0s;
	cursor: pointer;
	transition: all 0.3s ease;

	&:hover {
		color: ${({ theme }) => theme.palette.main};
		background: ${({ theme }) => theme.palette.bg_purp};
		border: 1px solid ${({ theme }) => theme.palette.inactive};
	}
`;

const SubmittedText = styled.div<{ isSuccess: boolean; isSubmitted: boolean }>`
	padding: 10px 20px;
	color: ${({ theme, isSuccess, isSubmitted }) =>
		isSuccess || (!isSuccess && !isSubmitted) ? theme.palette.main : theme.palette.white};
	background-color: ${({ theme, isSuccess }) => (isSuccess ? theme.palette.bg_purp : theme.palette.fail)};
	border-radius: 2px;
	margin-bottom: 20px;
`;
const ImgBlock = styled.div`
	& img {
		border-radius: 2px;
	}
`;

const TabBox = styled.div`
	position: relative;
	overflow-y: hidden;
	margin-bottom: 80px;

	@media (max-width: 767px) {
		margin-bottom: 60px;
	}
`;

const Row = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: MuseoSans;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;

	@media (max-width: 480px) {
		flex-direction: column;
	}
`;

const RowLine = styled.div`
	position: absolute;
	bottom: -1px;
	left: 0;
	right: 0;
	width: inherit;
	height: 2px;
	background: ${({ theme }) => theme.palette.inactiveTabLine};
`;

const TitleSelect = styled.h6`
	height: 100%;
	display: flex;
	align-items: center;
	font-family: MuseoSans;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	color: ${({ theme }) => theme.palette.main};
	padding: 24px 0px;
	position: relative;
	z-index: 1;

	@media (max-width: 480px) {
		flex-wrap: wrap;
		padding: 36px 0px 0px;
	}
`;

const Span = styled.span`
	@media (max-width: 480px) {
		width: 100%;
	}
`;
const TitleImage = styled.div<{ isActive: boolean }>`
	height: 100%;
	display: flex;
	align-items: center;
	padding: 25px 10px 26px;
	border-bottom: 2px solid ${({ theme, isActive }) => (isActive ? theme.palette.purp : 'transparent')};
	cursor: pointer;
	position: relative;
	z-index: 1;
`;

const HandIcon = styled.div`
	display: flex;
	align-items: center;
	width: 20px;
	object-fit: contain;
	margin: 0 10px;
	& img {
		max-width: 100%;
	}

	@media (max-width: 480px) {
		margin: 22px auto 0;
		width: 23px;

		& img {
			transform: rotate(90deg);
		}
	}
`;

const ProviderIcon = styled.div`
	width: 100px;
	height: 40px;
	position: relative;
	& img {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 100%;
		transform: translateY(-50%);
		object-fit: contain;
	}
`;

const ProviderIconNookal = styled(ProviderIcon)`
	& img {
		top: 50%;
		transform: translateY(-45%);
	}
`;

const ImgRow = styled.div`
	flex: 1 0 auto;
	display: flex;
	justify-content: space-around;
	padding-right: 50px;
	overflow-x: auto;

	@media (max-width: 575px) {
		padding-right: 0px;
	}

	@media (max-width: 480px) {
		flex: 1 0 100%;
		width: 100%;
	}
`;

export default HowToGuide;
