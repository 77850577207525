import React from 'react';
import styled from 'styled-components';

import loader from 'images/spinner.svg';

const LoaderComponent: React.FC<{ zIndex?: number }> = ({ zIndex = 210 }) => {
	return (
		<Loader zIndex={zIndex}>
			<img src={loader} alt='loader' />
		</Loader>
	);
};

const Loader = styled.div<{ zIndex: number }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: ${({ zIndex }) => zIndex};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	background-color: ${({ theme }) => theme.palette.modal_bg};
	backdrop-filter: blur(2px);
	box-shadow: 0px 0px 20px 20px rgba(255, 255, 255, 1) inset;

	& > img {
		width: 20%;
		height: 20%;
		max-width: 100%;
		object-fit: contain;
	}
`;

export default LoaderComponent;
